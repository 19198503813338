import React from "react";
import Modal from "./modal";
import PopupsApi from "../api/popupsApi";
import SubscriptionCv from "./shortcodes/subscriptionCv";
import SubscriptionTemplate from "./shortcodes/subscriptionTemplate";
import CheckoutContent from "./shortcodes/checkout/index";
import Newsletter from './shortcodes/newsletter';
import UtilsExitModal from '../utilsExitModal';
import NewsletterFormShortcodeModal from "./shortcodes/newsletterFormShortcodeModal";
import {parseCookies, setCookie} from "../base/cookieHelper";

const SHOW_LIMIT = 3;

class Show extends React.Component {

    state = {
        isOpen: false,
        isOpenOnExit: false,
        popupToShow: null,
        popupOnExit: null,
        timeoutId: null,
    };

    componentDidMount() {
        let startTimer = new Date;
        let tryShowModal = this.checkCookiesForShowLimit();
        if (tryShowModal || this.props.allowShowExitModal) {
            this.loadModal(startTimer);
        }
    }

    componentWillUnmount() {
        if(this.state.timeoutId)
         clearTimeout(this.state.timeoutId);
    }


    setOpen = (value) => {
        if (value) {
            document.documentElement.classList.add('is-reveal-open');
            this.afterShowPopup(this.state.popupToShow.id, true);
            this.setState({timeoutId: null});
        } else {
            document.documentElement.classList.remove('is-reveal-open');
        }
        this.setState({isOpen: value});
    };

    setOpenOnExit = (value) => {
        if (value && document.documentElement.classList.contains('is-reveal-open')) {
            console.log('popup on exit canceled');
            return
        }
        if (this.state.isOpen)
            this.setState({isOpen: false});
        else {
                if (value)
                    document.documentElement.classList.add('is-reveal-open');
                else
                    document.documentElement.classList.remove('is-reveal-open');
            }

        this.setState({isOpenOnExit: value});

        if (!value && this.state.timeoutId && this.state.popupToShow) {
            this.openModalRuleCheck(this.state.popupToShow, new Date)
        }

        if (value) {
            if (this.state.timeoutId) { clearTimeout(this.state.timeoutId); }
            this.afterShowPopup(this.state.popupOnExit.id);
        }
    };


    loadModal = (startTimer) => {
        let path = window.location.pathname;
        let _this = this;
        PopupsApi.getPopups(path).then((response) => {
                if (response.popup) {
                    _this.setState({popupToShow: response.popup});
                    _this.openModalRuleCheck(response.popup, startTimer)
                } else {
                    _this.setCookiesPageView();
                }
                if (response.popup_on_exit){
                    _this.setState({popupOnExit: response.popup_on_exit});
                    _this.openOnExitModal(response.popup_on_exit)
                }

       }).catch(error => {
            console.log(error);
        });
    };

    checkCookiesForShowLimit = () => {
        let popupCookieObj = parseCookies('ca_pop');
        let popupsShowed = popupCookieObj.showed;
        let showedIds = popupsShowed || [];
        return (showedIds.length < SHOW_LIMIT)
    };

    setCookiesPageView = () => {
        let popupCookieObj = parseCookies('ca_pop');
        let pageViews = this.trackPageView(popupCookieObj);
        setCookie('ca_pop', {showed: popupCookieObj.showed, pgViews: pageViews });
    };

    afterShowPopup = (id, reset = false) => {
        let popupCookieObj = parseCookies('ca_pop');
        let showedPopups = this.trackShowedPopup(popupCookieObj, id);
        setCookie('ca_pop',  {showed: showedPopups, pgViews: reset ? 0 :popupCookieObj.pgViews });
        let path = window.location.pathname;
        PopupsApi.setViewPopup(id, path);
    };

    openOnExitModal = (modal) => {
        if (modal.on_exit) {
            const removeExitIntent = UtilsExitModal({
                desktopTimeout: 7000,
                onExitIntent: () => {
                    this.setOpenOnExit(true);
                },
            });
        }
    };


    beforeShow = () => {
        let stillShowModal = this.checkCookiesForShowLimit() && !document.documentElement.classList.contains('is-reveal-open');
        if (stillShowModal)
          this.setOpen(true);
        else
            console.log('popup canceled')
    };

    openModalRuleCheck = (modal, startTimer) => {
        if (!modal.show_after_time) {
            this.setOpen(true);
        } else {
            let showAfter = (parseFloat(modal.show_after_time) * 1000) - (new Date - startTimer);
            if (showAfter <= 0) {
                this.beforeShow();
            } else {
              const timeoutId = window.setTimeout(this.beforeShow,showAfter);
              this.setState({timeoutId: timeoutId});
            }
        }
    };

    trackShowedPopup = (popupCookieObj, id = null) => {
        let popupsShowed = popupCookieObj.showed;
        let showedIds = popupsShowed || [];
        if (id)
          showedIds.push(id);
        return showedIds;
    };

    trackPageView = (popupCookieObj) => {
        let pageViewCounter = popupCookieObj.pgViews;
        if(pageViewCounter){
            pageViewCounter = parseInt(pageViewCounter) + 1;
        } else {
            pageViewCounter = 1;
        }
        return pageViewCounter;
    };

    renderContent = (htmlCode, popupId, newsletterFormAttrs) => {
        if (newsletterFormAttrs) {
            return <NewsletterFormShortcodeModal html={htmlCode} formAttrs={newsletterFormAttrs} close={() => this.state.isOpen ? this.setOpen(false) : this.setOpenOnExit(false)} />
        }

        if (htmlCode.indexOf("[SUBSCRIPTION_CV]") >= 0){
            return <SubscriptionCv close={() => this.state.isOpen ? this.setOpen(false) : this.setOpenOnExit(false) } popupId={popupId} />
        }
        if (htmlCode.indexOf("[SUBSCRIPTION_CV_TEMPLATE]") >= 0){
            return <SubscriptionTemplate close={() => this.state.isOpen ? this.setOpen(false) : this.setOpenOnExit(false) } popupId={popupId} />
        }
        if (htmlCode.indexOf("[CHECKOUT_EXIT]") >= 0){
            return <CheckoutContent close={() => this.state.isOpen ? this.setOpen(false) : this.setOpenOnExit(false) } popupId={popupId} />
        }
        if (htmlCode.indexOf("[NEWSLETTER]") >= 0){
            return <Newsletter close={() => this.state.isOpen ? this.setOpen(false) : this.setOpenOnExit(false) } popupId={popupId} />
        }
        return <div dangerouslySetInnerHTML={{__html: htmlCode}}/>;
    };

    render() {
        return (
            <React.Fragment>
                {!this.state.isOpen || !this.state.popupToShow ? null :
                    <Modal close={() => this.setOpen(false)} modalName={this.state.popupToShow.class_name} withCustomScripts={true}
                           closeIcon={this.state.popupToShow.close_icon} clickTracking={{id: this.state.popupToShow.id}}>
                        {this.renderContent(this.state.popupToShow.code, this.state.popupToShow.id, this.state.popupToShow.newsletter_form_attrs)}
                    </Modal>
                }
                {!this.state.isOpenOnExit || !this.state.popupOnExit ? null :
                    <Modal close={() => this.setOpenOnExit(false)} modalName={this.state.popupOnExit.class_name} withCustomScripts={true}
                           closeIcon={this.state.popupOnExit.close_icon} clickTracking={{id: this.state.popupOnExit.id}}>
                        {this.renderContent(this.state.popupOnExit.code, this.state.popupOnExit.id, this.state.popupOnExit.newsletter_form_attrs)}
                    </Modal>
                }
            </React.Fragment>

        );
    }

};

export default Show;