import {actions} from "react-fields";

export default class PricesApi {

    static getStartingPrice(service, onLoad, withOffer) {
        let serviceUrlParam = service;
        if (['cv_review', 'get_hired'].includes(service)) {
            serviceUrlParam = `cv-products/${service}`
        }
        const url = `/api/${serviceUrlParam}/${withOffer ? 'price_with_offer' : 'initial_price'}`;
        console.log(url);
        fetch(actions.getData(url))
            .then(actions.status)
            .then(actions.json)
            .then(function(data) {
                return data;
            }).then( async (response) => {
            onLoad(response);
        }).catch(error => {
            console.log(error);
        })
    }

    static getCVServicePrice(service, onLoad) {
        const url = `/api/cv-products/${service}/initial_price`;
        fetch(actions.getData(url))
            .then(actions.status)
            .then(actions.json)
            .then(function(data) {
                return data;
            }).then( async (response) => {
            onLoad(response);
        }).catch(error => {
            console.log(error);
        })
    }

}