import React, {useState, useEffect} from 'react';
import PricesApi from '../api/prices';

export default function DisplayPrice(props) {

    const [price, setPrice] = useState(null);
    const [valuePrice, setValuePrice] = useState(null);
    const [specialPrice, setSpecialPrice] = useState(null);
    const [offerLabel, setOfferLabel] = useState(null);
    const [currency, setCurrency] = useState(null);

    useEffect(() => {
        console.log('displayPrice');
        console.log(props.service);
        PricesApi.getStartingPrice(props.service, onSuccess, props.withOffer);
    }, []);

    const onSuccess = (response) => {
        setPrice(response.price);
        setCurrency(response.currency);
        setValuePrice(response.value_price);
        setSpecialPrice(response.special_price);
        if (response.special_offer) { setOfferLabel(response.special_offer_label); }
    };

    if (!price || !currency) {
        return null;
    } else if (!props.withOffer) {
        return `${currency.symbol}${price}`;
    } else if (!offerLabel) {
        return (
            <React.Fragment>
                {valuePrice ? <p className="cv-bundles-initial-price">VALUED AT {currency.symbol}{valuePrice}</p> : null}
                <h3 className=" cv-bundles-price secondary-clr"><span>Just</span> {currency.symbol}{price}</h3>
            </React.Fragment>
        )
    } else {
        return (
            <React.Fragment>
                {offerLabel ? <p className="checkout-offer-label float-right">{offerLabel}</p> : <p className="cv-bundles-initial-price">VALUED AT {currency.symbol}{price}</p>}
                <h3 className="cv-bundles-price secondary-clr"><span>Just</span> {currency.symbol}{specialPrice}</h3>
            </React.Fragment>
        )
    }
}